// spacing
$spacing: 8px;

// surfaces
$color-primary: #F46363;
$color-secondary: #463B4F;
$color-surface: #FFFFFF;
$color-card-surface: rgba(#ffE5E5, 0.1);
$color-background: #F4F4F4;
$color-outline: rgba(#000000, 0.12);
$color-is-active: #E9E2EF;

// content
$on-surface-high: rgba(#000000, 0.87);
$on-surface-medium: rgba(#000000, 0.60);
$on-surface-disabled: rgba(#000000, 0.38);

$on-primary-high: rgba(#FFFFFF, 1);
$on-primary-medium: rgba(#FFFFFF, 0.74);
$on-primary-disabled: rgba(#FFFFFF, 0.38);

$on-secondary-high: rgba(#FFFFFF, 1);
$on-secondary-medium: rgba(#FFFFFF, 0.74);
$on-secondary-disabled: rgba(#FFFFFF, 0.38);

// overlays
$overlay-black-hover: rgba(#000000, 0.04);
$overlay-black-pressed: rgba(#000000, 0.012);
$overlay-primary-selected: rgba(255, 153, 153, 0.08);
// $overlay-primary-selected: rgba(#5600E8, 0.18);

// hover
$color-hover: #00000010;
$color-hover-medium: #00000020;
$color-hover-chart: #ffdbdb;
$color-primary-hover: #C24B4B;

// colors text
$text-medium: #666666;

// box-shadow
$box-shadow-card: 0px 24px 38px rgba(0, 0, 0, 0.06), 0px 9px 46px rgba(0, 0, 0, 0.08), 0px 11px 15px rgba(0, 0, 0, 0.12);
$box-shadow-header: 0px 4px 5px rgba(0, 0, 0, 0.06), 0px 1px 10px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);

$box-shadow-01: 0px 1px 1px rgba(0, 0, 0, 0.06), 0px 2px 1px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.12);
$box-shadow-02: 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 3px 1px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.12);
$box-shadow-03: 0px 3px 4px rgba(0, 0, 0, 0.06), 0px 3px 3px rgba(0, 0, 0, 0.08), 0px 1px 8px rgba(0, 0, 0, 0.12);
$box-shadow-04: 0px 4px 5px rgba(0, 0, 0, 0.06), 0px 1px 10px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
$box-shadow-06: 0px 6px 10px rgba(0, 0, 0, 0.06), 0px 1px 18px rgba(0, 0, 0, 0.08), 0px 3px 5px rgba(0, 0, 0, 0.12);
$box-shadow-08: 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 3px 14px rgba(0, 0, 0, 0.06), 0px 5px 5px rgba(0, 0, 0, 0.08);
$box-shadow-12: 0px 12px 17px rgba(0, 0, 0, 0.06), 0px 5px 22px rgba(0, 0, 0, 0.08), 0px 7px 8px rgba(0, 0, 0, 0.12);
$box-shadow-16: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 6px 30px rgba(0, 0, 0, 0.08), 0px 8px 10px rgba(0, 0, 0, 0.12);
$box-shadow-24: 0px 24px 38px rgba(0, 0, 0, 0.06), 0px 9px 46px rgba(0, 0, 0, 0.08), 0px 11px 15px rgba(0, 0, 0, 0.12);

// layout
$layout-header-height: 62px;
$layout-tabs-height: 48px;
$layout-filter-width: 350px;
$layout-filter-width-collapsed: 36px;
$layout-display-width: 380px;

// z-index
$z-index-header: 50;
$z-index-help: 40;
$z-index-display: 31;
$z-index-filters: 30;
$z-index-tabs: 20;
$z-index-content: 10;


// table
$table-background-color: #00000002;
$table-border-color: #00000020;

// alerts
$color-success: #2ECC71;
$color-warning: #F1C40F;
$color-danger: #D35400;

// image height
$standard-image-height: 160px;
