
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$no-kno-primary: mat.define-palette(mat.$indigo-palette);
$no-kno-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$no-kno-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$no-kno-theme: mat.define-light-theme((
  color: (
    primary: $no-kno-primary,
    accent: $no-kno-accent,
    warn: $no-kno-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($no-kno-theme);

/* You can add global styles to this file, and also import other style files */

@import '~src/styles';

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: $color-background;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;

  .mat-dialog-container {
    border-radius: 0;
    overflow-x: hidden;
    max-height: 90vh;
    padding: $spacing * 2;
  }
}

.mat-tooltip {
  margin: 4px !important;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 0,
  'GRAD' 0,
  'opsz' NaN
}
