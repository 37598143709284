
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$no-kno-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$no-kno-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$no-kno-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$no-kno-theme: mat.m2-define-light-theme((
  color: (
    primary: $no-kno-primary,
    accent: $no-kno-accent,
    warn: $no-kno-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($no-kno-theme);

/* You can add global styles to this file, and also import other style files */

@import 'src/styles';

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: $color-background;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;

  .mat-mdc-dialog-container {
    border-radius: 0;
    overflow-x: hidden;
    max-height: 90vh;
    padding: $spacing * 2;
  }
}

.mdc-tab__text-label {
  color: rgba($color-secondary, 0.7) !important;
}

.mdc-tab-indicator__content--underline {
  border-color: $color-primary !important;
}

.mdc-radio__outer-circle {
  border-color: $color-primary !important;
}

.mdc-radio__inner-circle {
  border-color: $color-primary !important;
}

.mat-mdc-unelevated-button {
  background-color: $color-primary !important;
  color: $color-surface !important;
}

.mat-mdc-unelevated-button>.mat-icon {
  margin: 0 !important;
}

.mat-mdc-tooltip {
  margin: 4px !important;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 0,
  'GRAD' 0,
  'opsz' NaN
}

